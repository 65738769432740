
@use 'stylesheets/foundation/colors';

.upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  padding: 4rem 2rem 2rem 2rem;

  background: colors.$white;
  border-radius: 12px;

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.button {
  font-weight: 700;
}

.hidden {
  display: none;
}
